body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.header {
  background: #424141;
  height: 40px;
  width: 100%;
  display: inline-flex;
}

.header .logo {
  padding: 8px;
  display: block;
  max-height: 24px;
}

.header .title {
  color: white;
  display: block;
  text-align: center;
  padding-top: 12px;
  width: 100%;
  font-weight: bold;
}

h1, h2, h3, h4 {
  margin-left: 10px;
}

.data-form {
  border: 1px lightgray solid;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
}

.vertical {
  width: 30%;
  min-width: 300px;
  display: inline-flex;
}

.group-title {
  background: white;
  position: absolute;
  color: gray;
  margin-top: -30px;
  padding-left: 4px;
  padding-right: 4px;
  display: inline-flex;
}

.input-line {
  width: 100%;
  display: inline-flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

.description {
  width: 290px;
  color: #27292f;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  padding-top: 5px;
  text-align: right;
}

.error * {
  color: #b94a48;
  border-color: #b94a48;
}

input, select {
  color: #222;
  background: white;
  border-radius: 4px;
  border: 1px #CCC solid;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  font-size: 13px;
  line-height: 18px;
  height: 18px;
  margin-left: 300px;
  position: absolute;
  padding: 4px 6px 3px;
}

select {
  height: 27px;
}

pre {
  background: #f5f5f5;
  border: gray 1px solid;
  border-radius: 7px;
  padding: 10px;
  font-size: 12px;
  line-height: 15px;
  word-wrap: break-spaces;
  overflow-x: auto;
  overflow-y: hidden;
}

button {
  margin: 0 auto;
  background: white linear-gradient(to bottom, #fff, #ccc);
  color: #000;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 4px 10px;
  border: 2px solid #ddd;
  border-color: rgba(0, 0, 0, .15) rgba(0, 0, 0, .15) rgba(0, 0, 0, .25);
  border-radius: 4px;
  cursor: pointer;
}

button:active {
  background: white linear-gradient(to top, #fff, #ccc);
}

.active {
  background: white linear-gradient(to top, #ffd435, #ccbc27);
  outline: none;
}

.rendered-data {
  display: none;
}

.active-render {
  display: block;
}

.download {
  background: #edba20;
  border: 1px solid #bbb;
  border-color: rgba(0, 0, 0, .15) rgba(0, 0, 0, .15) rgba(0, 0, 0, .25);
}

.download:active {
  background: #edba20 linear-gradient(to bottom, #c1971b, #ffca20);
}

.copy {
  background: #eee;
  border: 1px solid #bbb;
  border-color: rgba(0, 0, 0, .15) rgba(0, 0, 0, .15) rgba(0, 0, 0, .25);
}

.hidden {
  margin: 30px 20px;
  padding: 0;
}

.hidden * {
  display: none;
}

.hidden .group-title {
  margin-top: -10px;
  margin-left: 20px;
  display: inline-flex;
}

.toggle-icon {
  width: 0;
  height: 0;
  background: white;
  display: block;
  margin-left: 5px;
  margin-top: 6px;

  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: none;
  border-bottom: 6px solid gray;
}

.hidden .toggle-icon {
  width: 0;
  height: 0;
  background: white;
  display: block;
  margin-left: 5px;
  margin-top: 6px;

  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid gray;
  border-bottom: none;
}

.togglable {
  cursor: pointer;
}